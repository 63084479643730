import './App.css';
import MainContainer from './components/MainContainer';

function App() {
  return (
    <MainContainer/>
  );
}

export default App;
